import { Type, plainToClass } from "class-transformer";

interface UserLog {
  action: string;
  createdAt: string;
}

interface CourseActivity {
  title?: string;
  activity?: string;
  UserLogs: Array<UserLog>;
}

interface ModuleInterface {
  id?: number;
  title?: string;
  order?: number;
  length?: number;
  CourseActivities: Array<CourseActivity>;
  videos(): Array<CourseActivity>;
  exercises(): Array<CourseActivity>;
}

class Module implements ModuleInterface {
  CourseActivities: Array<CourseActivity> = [];
  videos() {
    return this.CourseActivities.filter(function (step: CourseActivity) {
      return step.activity === "video";
    });
  }
  exercises() {
    return this.CourseActivities.filter(function (step: CourseActivity) {
      return step.activity === "exercise";
    });
  }
}

class Course {
  @Type(() => Module)
  modules!: Module[];
}

function getCourse(fetch: Function): Promise<Course> {
  const prefix = process.env.REACT_APP_API_URL || "";
  return fetch(prefix + "/api/course")
    .then((res: any) => res.text())
    .then((res: any) => JSON.parse(res))
    .then((course: Course) => {
      return plainToClass(Course, course);
    });
}

function getModule(
  fetch: Function,
  module_id: number
): Promise<ModuleInterface> {
  const prefix = process.env.REACT_APP_API_URL || "";
  return fetch(prefix + `/api/module/${module_id}`)
    .then((res: any) => res.text())
    .then((res: any) => JSON.parse(res))
    .then((module: Module) => plainToClass(Module, module));
}

export { getCourse, getModule, Course, Module };
export type { CourseActivity };
