import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  Button,
} from "@material-ui/core";
import NavBar from "../components/NavBar";
import AnnotatedIcon from "../components/AnnotatedIcon";
import VideoIcon from "@material-ui/icons/OndemandVideo";
import ExerciseIcon from "@material-ui/icons/Assessment";
import TimerIcon from "@material-ui/icons/AccessTime";

import toTimeString from "../lib/toTimeString";
import { CourseConsumer } from "../lib/CourseContext";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 20,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "50%",
  },
  index: {
    width: 40,
  },
  title: {
    width: 400,
    textAlign: "left",
  },
  listitem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    paddingLeft: 15,
    paddingBottom: 3,
    textAlign: "center",
  },
}));

const TimeIcon = (props) => {
  const { seconds, classes } = props;

  if (!seconds) return null;

  const time = toTimeString(seconds);
  return (
    <AnnotatedIcon icon={<TimerIcon />} className={classes.icon} text={time} />
  );
};

const Index = () => {
  const classes = useStyles();

  return (
    <>
      <NavBar title="FX Options Course" />
      <Container className={classes.container}>
        <Paper variant="outlined">
          <CourseConsumer>
            {({ course }) =>
              course?.modules.map((module, index) => (
                <List key={index} className={classes.root} component="nav">
                  <ListItem className={classes.listitem}>
                    <Button
                      component={Link}
                      variant="outlined"
                      to={`/module/${module.id}`}
                    >
                      <Typography className={classes.index} variant="h6">
                        {index + 1}
                      </Typography>
                      <Typography className={classes.title} variant="h6">
                        {module.title}
                      </Typography>
                      <AnnotatedIcon
                        className={classes.icon}
                        icon={<VideoIcon />}
                        text={
                          module.CourseActivities.filter(
                            (step) => step.activity === "video"
                          ).length
                        }
                      />
                      <AnnotatedIcon
                        className={classes.icon}
                        icon={<ExerciseIcon />}
                        text={
                          module.CourseActivities.filter(
                            (step) => step.activity === "exercise"
                          ).length
                        }
                      />
                      <TimeIcon classes={classes} seconds={module.length} />
                    </Button>
                  </ListItem>
                </List>
              ))
            }
          </CourseConsumer>
        </Paper>
      </Container>
    </>
  );
};

export default Index;
