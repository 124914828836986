import { createContext, useEffect, useState, useContext } from "react";

import { logStartActivity, logCompleteActivity } from "../services/log";
import { UserContext } from "./UserContext";

const StateContext = createContext();

const default_state = {
  started: {},
  completed: {},
};

const StateProvider = ({ children }) => {
  const [state, setState] = useState(default_state);

  const { fetch } = useContext(UserContext);

  useEffect(() => {
    const prefix = process.env.REACT_APP_API_URL || "";
    fetch(prefix + "/api/activity")
      .then((res) => res.text())
      .then((res) => JSON.parse(res))
      .then((res) => setState(res));
  }, [fetch]);

  const methods = {
    setStarted: (activity_id) => {
      logStartActivity(activity_id).then(() => {
        state.started[activity_id] = Date.now();
        setState({ ...state });
      });
    },
    setCompleted: (activity_id) => {
      logCompleteActivity(activity_id).then(() => {
        state.completed[activity_id] = Date.now();
        setState({ ...state });
      });
    },
    getStarted: () => {
      return state.started;
    },
    getCompleted: () => {
      return state.completed;
    },
  };

  return (
    <StateContext.Provider value={methods}>{children}</StateContext.Provider>
  );
};

const StateConsumer = StateContext.Consumer;

export { StateConsumer, StateProvider, StateContext };
