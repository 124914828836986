import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import TargetIcon from "@material-ui/icons/TrackChanges";

const BriefingDialog = (props) => {
  const { open, onClose, nextClick, step } = props;

  const activity = step;

  if (!activity) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{activity.title}</DialogTitle>
      <DialogContent>
        <TargetIcon style={{ color: "red", verticalAlign: "middle" }} />{" "}
        {activity.briefing}
      </DialogContent>
      <DialogActions>
        <Button onClick={nextClick}>Start</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BriefingDialog;
