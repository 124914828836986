import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const mytheme = createMuiTheme({
  typography: {
    fontFamily: ["proxima-nova", "sans-serif"],
  },
  overrides: {
    MuiDialogContent: {
      root: {
        fontSize: 20,
      },
    },
    MuiLink: {
      root: {
        fontFamily: ["Arial"],
        fontSize: 13.3,
      },
    },
  },
});

const navtheme = createMuiTheme({
  ...mytheme,
  typography: {
    button: {
      textTransform: "none",
      fontWeight: 400,
      fontSize: 18,
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#ece6db",
        color: "black",
      },
    },
    MuiToolBar: {},
  },
});

const NavThemeProvider = (props) => {
  return <ThemeProvider theme={navtheme}>{props.children}</ThemeProvider>;
};

const MyThemeProvider = (props) => {
  return <ThemeProvider theme={mytheme}>{props.children}</ThemeProvider>;
};

export { NavThemeProvider, MyThemeProvider };
