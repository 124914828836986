import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Typography,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyDialog = ({ open, title, onClose, children }) => {
  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

const TC = () => (
  <>
    <Typography gutterBottom>
      THESE TERMS AND CONDITIONS OF USE (THE “TERMS”) SHALL GOVERN YOUR USE OF
      THE COURSE SYSTEM AND BY CLICKING THE “I ACCEPT” BUTTON DISPLAYED AS PART
      OF THE REGISTRATION PROCESS YOU AGREE TO ACCEPT THESE TERMS.
    </Typography>
    <Typography gutterBottom>
      You shall comply with the Course System instructions regarding the use of
      the Course System.
    </Typography>
    <Typography gutterBottom>
      You shall not permit any other person to access or use the Course System.
    </Typography>
    <Typography gutterBottom>
      You shall keep confidential and not disclose to any other person your
      personal login used by you to access the System and you also agree to take
      all reasonable steps to prevent others from using your account / username
      and password.
    </Typography>
    <Typography gutterBottom>
      You must immediately notify the Course System helpdesk if you become aware
      of or suspect that your login has become known to any other person or if
      you become aware of or suspect any unauthorised access to or use of the
      System.
    </Typography>
    <Typography gutterBottom>
      Any personal information provided by you on the Course System, can be
      controlled by you. Your learning progress and exercise results, shall be
      controlled by the system.
    </Typography>
    <Typography gutterBottom>
      ACI FMA is not responsible to you for any failings of the Course System.
      Whilst every effort has been made by ACI FMA to ensure accuracy on the
      System, ACI FMA cannot guarantee it and you should not rely on the Course
      System.
    </Typography>
    <Typography gutterBottom>
      You must not violate the security of the System nor use the Course System
      to send unsolicited bulk email nor corrupt or interfere with the operation
      of the System nor expose the Course System to any viruses, worms or any
      other computer code, files or programs which might interrupt, limit or
      interfere with the functionality of any computer software or hardware or
      telecommunications equipment.
    </Typography>
    <Typography gutterBottom>
      You also agree not to distribute or transmit through the Course System any
      material that is unlawful, harmful, threatening, defamatory, obscene,
      infringing, harassing or racially or ethnically offensive; facilitates
      illegal activity; depicts sexually explicit images; or promotes unlawful
      violence, discrimination based on race, gender, colour, religious belief,
      sexual orientation, disability, or any other illegal activities including
      the advocating of terrorism.
    </Typography>
    <Typography gutterBottom>
      You acknowledge that the Course System and the software components of the
      Course System are the proprietary and confidential material of Supplier.
    </Typography>
    <Typography gutterBottom>
      Supplier reserves the right to monitor your use of the Course System. Any
      such monitoring shall be solely for the purpose of ensuring compliance
      with these Terms or other legitimate purposes of Supplier.
    </Typography>
    <Typography gutterBottom>
      You shall immediately discontinue your use of the Course System on notice
      from either Supplier or your employer.
    </Typography>
    <Typography gutterBottom>
      Supplier may modify these Terms from time to time by notice to you. Notice
      may be given by way of the logon screen for the Course System. You shall
      be deemed to have accepted such modifications by continuing to access
      and/or use the Course System and/or by clicking any on screen “I Accept”
      or similar button.
    </Typography>
    <Typography gutterBottom>
      These Terms are governed by the laws of the Netherlands and you submit to
      the exclusive jurisdiction of the courts in Amsterdam, the Netherlands to
      resolve any dispute between them arising under or in connection with these
      Terms.
    </Typography>
  </>
);

const DialogTC = ({ open, onClose }) => {
  return (
    <MyDialog open={open} onClose={onClose} title="Terms and Conditions">
      <TC />
    </MyDialog>
  );
};

export { DialogTC, TC };
