import { Box } from "@material-ui/core";
import GraduationCapRegularIcon from "./GraduationCapRegularIcon";
const Title = () => (
  <>
    <GraduationCapRegularIcon style={{ marginRight: 5 }} />
    <Box display="flex" flexDirection="column" style={{ paddingRight: 10 }}>
      <span style={{ color: "#FFC300", lineHeight: 0.9, fontWeight: 600 }}>
        FX Options
      </span>
      <span style={{ lineHeight: 0.9 }}>Course</span>
    </Box>
  </>
);

export default Title;
