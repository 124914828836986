import { createContext, useEffect, useState, useCallback } from "react";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [state, setState] = useState({ user: null, next: null });
  const [status, setStatus] = useState(200);

  // Override fetch function
  const fetch = (...args) => {
    return window
      .fetch(...args)
      .then((res) => {
        if (res.status >= 400) {
          setStatus(res.status);
          return Promise.reject(res);
        }
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  };

  const getUser = useCallback(() => {
    return fetch("/api/userdetails")
      .then((res) => res.json())
      .catch((err) => console.log(err));
  }, []);

  const getNext = useCallback(() => {
    return fetch("/api/activity/next")
      .then((res) => res.json())
      .catch((err) => console.log(err));
  }, []);

  const userAcceptTerms = () => {
    fetch("/api/userdetails/acceptterms", { method: "PUT" })
      .then(() => getUser())
      .then((res) => setState({ ...state, user: res }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const u = await getUser();
      const n = await getNext();
      setState({ user: u, next: n });
    };
    fetchData();
  }, [setState, getUser, getNext]);

  const nextUrl = () => {
    return `/module/${state.next?.coursemodule_id}/${state.next?.activity_id}`;
  };

  return (
    <UserContext.Provider
      value={{ state, nextUrl, userAcceptTerms, fetch, status }}
    >
      {children}
    </UserContext.Provider>
  );
};

const UserConsumer = UserContext.Consumer;

export { UserProvider, UserConsumer, UserContext };
