import { ButtonBase, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import NextIcon from "@material-ui/icons/SkipNext";
import PrevIcon from "@material-ui/icons/SkipPrevious";
import PropTypes from "prop-types";

const CloseButton = (props) => {
  const { onClick } = props;
  return (
    <ButtonBase style={{ float: "right" }} onClick={onClick}>
      <CloseIcon />
    </ButtonBase>
  );
};

const TextButton = (props) => {
  const { onClick, text } = props;
  return (
    <Button variant="outlined" style={{ float: "right" }} onClick={onClick}>
      {text}
    </Button>
  );
};

const NextButton = (props) => {
  const { onClick } = props;
  return (
    <ButtonBase style={{ float: "right" }} onClick={onClick}>
      <NextIcon />
    </ButtonBase>
  );
};

const PrevButton = (props) => {
  const { onClick } = props;
  return (
    <ButtonBase style={{ float: "right" }} onClick={onClick}>
      <PrevIcon />
    </ButtonBase>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
NextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
PrevButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export { CloseButton, NextButton, PrevButton, TextButton };
