import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Paper,
  Box,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  Divider,
} from "@material-ui/core";
import Activity from "../components/Activity";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import DoneIcon from "@material-ui/icons/Done";
import InProgressIcon from "@material-ui/icons/Autorenew";

import { CourseContext } from "../lib/CourseContext";
import toTimeString from "../lib/toTimeString";
import { StateProvider, StateContext } from "../lib/StateContext";
import NextDialog from "../components/NextDialog";
import ObjectiveButton from "../components/ObjectiveButton";
import { NavThemeProvider } from "../themes/ElacTheme";
import AssertTerms from "../components/AssertTerms";
import CheckAPIOk from "../components/CheckAPIOk";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    textAlign: "left",
    padding: 15,
    fontWeight: "bold",
  },
  activeTab: {
    fontWeight: "bold",
    color: "black",
  },
  activeMenu: {
    whiteSpace: "break-spaces",
    fontWeight: "bold",
    color: "black",
    lineHeight: 1.5,
  },
  nonActiveMenu: {
    whiteSpace: "break-spaces",
    lineHeight: 1.5,
  },
  tabs: {
    height: 380,
    width: 320,
    verticalAlign: "top",
    margin: 5,
    "& .MuiTab-root.Mui-selected": {
      fontWeight: "bold",
    },
  },
  tabcontainer: {
    height: 490,
    display: "flex",
  },
  tab: {
    width: 320,
    maxWidth: 320,
    minHeight: 40,
  },
  tabpanel: {
    margin: 5,
  },
  label2: {
    display: "inherit",
  },
  titles: {
    textTransform: "capitalize",
    width: "100%",
    textAlign: "left",
  },
  icons: {
    color: "green",
    display: "inline-block",
    width: 24,
  },
  time: {
    marginLeft: 10,
  },
}));

const TabPanel = (props) => {
  const classes = useStyles();
  return <Box className={classes.tabpanel}>{props.children}</Box>;
};

const ProgressIcon = (props) => {
  const { started, completed, classes } = props;

  if (completed)
    return (
      <Tooltip title="Completed">
        <DoneIcon className={classes.icons} />
      </Tooltip>
    );
  else if (started)
    return (
      <Tooltip title="In progress">
        <InProgressIcon className={classes.icons} />
      </Tooltip>
    );
  else return <Box className={classes.icons} />;
};

const Label = (props) => {
  const { started, completed, step, classes } = props;
  return (
    <Box display="flex" alignItems="center" style={{ width: "100%" }}>
      <Box className={classes.titles}>
        {step.title || step.name || step.video}
      </Box>
      <Box className={classes.label2}>
        <Box className={classes.time}>{toTimeString(step.length)}</Box>
        <ProgressIcon
          started={started}
          completed={completed}
          classes={classes}
        />
      </Box>
    </Box>
  );
};

const ActivityMenu = (props) => {
  const { value, classes, module, onChange } = props;

  const { getStarted, getCompleted } = useContext(StateContext);

  const started = getStarted();
  const completed = getCompleted();

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      className={classes.tabs}
      value={value}
      onChange={onChange}
    >
      {module.CourseActivities?.map((step, index) => (
        <Tab
          className={classes.tab}
          id={`tab-${index}`}
          key={index}
          label={
            <Label
              started={started[step.id]}
              completed={completed[step.id]}
              step={step}
              classes={classes}
            />
          }
        />
      ))}
    </Tabs>
  );
};

const Module = () => {
  const { module_no, step_no } = useParams();

  const { course } = useContext(CourseContext);
  const history = useHistory();
  const updateHistory = (newpath) => {
    if (history.location.pathname !== newpath) {
      history.replace(newpath);
    }
  };

  const classes = useStyles();

  const [showNext, setShowNext] = useState(false);

  if (!course)
    return (
      <StateProvider>
        <NavThemeProvider>
          <NavBar />
        </NavThemeProvider>
        <AssertTerms />
        <CheckAPIOk />
      </StateProvider>
    );

  course.goto(module_no, step_no);

  updateHistory(`/module/${course.moduleno}/${course.stepno}`);

  const handleChange = (event, index) => {
    course.gotostep(index + 1);
    updateHistory(`/module/${course.moduleno}/${course.stepno}`);
  };

  const handleDone = () => {
    setShowNext(true);
  };

  const handleClose = () => {
    setShowNext(false);
  };

  const handleNext = () => {
    course.next();
    updateHistory(`/module/${course.moduleno}/${course.stepno}`);
  };

  const handlePrev = () => {
    course.prev();
    updateHistory(`/module/${course.moduleno}/${course.stepno}`);
  };

  const handleEnd = () => {
    course.goto(1, 1);
    updateHistory("/module/1/1");
  };

  // Shortcut
  const module = course.currentmodule();
  const step = course.currentstep();

  return (
    <StateProvider>
      <NavThemeProvider>
        <NavBar title="FX Options Course" />
      </NavThemeProvider>
      <AssertTerms />
      <CheckAPIOk />
      <Container style={{ width: 1340, padding: 10 }}>
        <Paper variant="outlined">
          <Typography variant="h5" className={classes.title}>
            {course.moduleno}. {module.title}
          </Typography>
          <Box className={classes.tabcontainer}>
            <Box>
              <Divider />
              <Box
                style={{ paddingLeft: 15, paddingBottom: 10, paddingTop: 10 }}
              >
                <ObjectiveButton style={{ paddingLeft: 0 }} module={module} />
              </Box>
              <Divider />
              <ActivityMenu
                classes={classes}
                value={course.stepidx()}
                onChange={handleChange}
                module={module}
              />
            </Box>
            <TabPanel>
              <Activity
                handleClose={handleClose}
                handleDone={handleDone}
                handleNext={handleNext}
                handlePrev={handlePrev}
                step={step}
              />
            </TabPanel>
          </Box>
        </Paper>
      </Container>
      <NextDialog
        open={showNext}
        course={course}
        nextClick={handleNext}
        onClose={handleClose}
        handleEnd={handleEnd}
      />
      <Footer />
    </StateProvider>
  );
};

export default Module;
