import React from "react";
import "./App.css";
import "reflect-metadata";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import Index from "./pages/Index";
import Module from "./pages/Module";
import { UserConsumer } from "./lib/UserContext";

function App() {
  return (
    <UserConsumer>
      {({ state, nextUrl }) =>
        state.user && state.next ? (
          <Router>
            <Switch>
              <Route path="/index" component={Index} />
              <Route path="/module/:module_no/:step_no" component={Module} />
              <Redirect from="/module/:module_no" to="/module/:module_no/1" />
              <Redirect from="/module" to={nextUrl()} />
              <Redirect from="/" to="/module" />
            </Switch>
          </Router>
        ) : null
      }
    </UserConsumer>
  );
}

export default App;
