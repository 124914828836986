import VideoActivity from "./Activity/VideoActivity";
import ExerciseActivity from "./Activity/ExerciseActivity";
import { StateContext } from "../lib/StateContext";
import { useContext } from "react";

const Activity = (props) => {
  const { step, handleStart, handleDone } = props;

  const { setStarted, setCompleted, getCompleted } = useContext(StateContext);

  const onStart = () => {
    setStarted(step.id);
    handleStart && handleStart();
  };

  const onDone = () => {
    setCompleted(step.id);
    handleDone && handleDone();
  };

  if (!step) return null;
  if (step.activity === "video")
    return (
      <VideoActivity
        video={step.name}
        handleStart={onStart}
        handleDone={onDone}
        allowSeek={!!getCompleted()[step.id]}
      />
    );
  if (step.activity === "exercise")
    return (
      <ExerciseActivity handleStart={onStart} handleDone={onDone} step={step} />
    );
  return <div>Unknown activity for: {step.title}</div>;
};

export default Activity;
