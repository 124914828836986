import React, { useContext } from "react";
import { UserContext } from "../lib/UserContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

const CheckAPIOk = () => {
  const { status } = useContext(UserContext);

  if (status === 401)
    return (
      <Dialog open={true}>
        <DialogTitle>Session expired</DialogTitle>
        <DialogContent>
          Your session has expired. If you want to continue, please log into
          Elac and come back to the FXOptions course.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location = "https://elac.acifma.com";
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );

  if (status === 404)
    return (
      <Dialog open={true}>
        <DialogTitle>Connection error</DialogTitle>
        <DialogContent>
          There is an issue with your connection or our service. Please reload
          or go back to Elac.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location = "/";
            }}
          >
            Reload
          </Button>
          <Button
            onClick={() => {
              window.location = "https://elac.acifma.com";
            }}
          >
            Back to elac
          </Button>
        </DialogActions>
      </Dialog>
    );

  return null;
};

export default CheckAPIOk;
