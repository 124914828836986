import { Typography, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";

const AnnotatedIcon = (props) => {
  const { icon, text, className, tooltip } = props;

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <div className={className}>
          {icon}
          <Typography style={{ lineHeight: 0.3 }} variant="body2">
            {text}
          </Typography>
        </div>
      </Tooltip>
    );
  } else
    return (
      <div className={className}>
        {icon}
        <Typography style={{ lineHeight: 0.3 }} variant="body2">
          {text}
        </Typography>
      </div>
    );
};

AnnotatedIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string.isRequired,
};

export default AnnotatedIcon;
