import React from "react";
import Button from "@material-ui/core/Button";
import CourseIntro from "./CourseIntro";

const About = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button color="inherit" onClick={handleClick}>
        Course Information
      </Button>
      <CourseIntro open={open} onClose={handleClose} />
    </>
  );
};

export default About;
