import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AnnotatedIcon from "../components/AnnotatedIcon";
import VideoIcon from "@material-ui/icons/OndemandVideo";
import ExerciseIcon from "@material-ui/icons/Assessment";
import toTimeString from "../lib/toTimeString";
import { CourseConsumer } from "../lib/CourseContext";
import { StateContext } from "../lib/StateContext";
import { ModuleStateIcon } from "./StateComponents";

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
  },
  button: {
    paddingLeft: 5,
  },
  index: {
    width: 40,
  },
  dialogtitle: {
    fontSize: 25,
  },
  title: {
    width: 500,
    textAlign: "left",
    fontSize: 16,
  },
  time: {
    width: 20,
    textAlign: "right",
    marginRight: 10,
  },
  icon: {
    color: "green",
    textAlign: "center",
    paddingLeft: 10,
  },
}));

const TheList = (props) => {
  const { modules, onClick } = props;
  const classes = useStyles();

  const { getCompleted } = useContext(StateContext);
  const completed = getCompleted();

  return (
    <List>
      {modules.map((module, index) => (
        <ListItem key={module.id}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => onClick(index + 1)}
          >
            <Typography className={classes.index} variant="h6">
              {index + 1}
            </Typography>
            <Typography className={classes.title} variant="h6">
              {module.title}
            </Typography>
            <Typography className={classes.time} variant="h6">
              {module.length ? toTimeString(module.length) : ""}
            </Typography>

            <AnnotatedIcon
              className={classes.icon}
              icon={<VideoIcon />}
              text={module.videos().length}
              tooltip={`${module.videos().length || "no"} videos`}
            />
            <AnnotatedIcon
              className={classes.icon}
              icon={<ExerciseIcon />}
              text={module.exercises().length}
              tooltip={`${module.exercises().length || "no"} exercise(s)`}
            />
            <ModuleStateIcon
              module={module}
              completed={completed}
              classes={classes}
            />
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

const ModuleList = ({ style }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const history = useHistory();

  const handleGoto = (m) => {
    history.replace(`/module/${m}/1`);
    handleClose();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        style={style}
        color="inherit"
        onClick={handleClick}
        className={classes.menuButton}
      >
        Main Menu
      </Button>
      <Dialog
        maxWidth={false}
        className={classes.root}
        aria-labelledby="simple-dialog-title"
        open={open}
        onClose={handleClose}
        PaperProps={{ className: classes.aboutDialog }}
      >
        <DialogTitle id="simple-dialog-title">
          <Typography className={classes.dialogtitle}>
            FX Options Course module list
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CourseConsumer>
            {({ course }) => (
              <TheList
                modules={course?.course.modules || []}
                onClick={handleGoto}
              />
            )}
          </CourseConsumer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withRouter(ModuleList);
