import { createContext, useEffect, useState, useContext } from "react";
import { getCourse } from "../services/course";
import { CourseState } from "../services/coursestate";
import { UserContext } from "./UserContext";

const CourseContext = createContext();

const CourseProvider = ({ children }) => {
  const [state, setState] = useState();
  const [course, setCourse] = useState();

  const isAuthenticated = true;

  const { fetch } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        getCourse(fetch)
          .then((c) => {
            console.log("Loaded course from API");
            setCourse(new CourseState(c, state, setState));
          })
          .catch(() => {
            console.log("API Load failed");
          });
      }
    })();
  }, [isAuthenticated, state, fetch]);

  return (
    <CourseContext.Provider value={{ course, state }}>
      {children}
    </CourseContext.Provider>
  );
};

const CourseConsumer = CourseContext.Consumer;

export { CourseConsumer, CourseProvider, CourseContext };
