import React, { useContext } from "react";
import { UserContext } from "../lib/UserContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { TC } from "./TermsAndConditions";

const AssertTerms = () => {
  const { state, userAcceptTerms } = useContext(UserContext);

  const user = state.user;

  const open = !!(user && !user.termsAcceptedAt);

  const accept = () => {
    userAcceptTerms();
  };

  const reject = () => {
    if (
      !window.confirm(
        "Rejecting the terms will take you back to ELAC.  Are you sure?"
      )
    )
      return;
    window.location = "https://elac.acifma.com";
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Accept Terms and Conditions</DialogTitle>
      <DialogContent>
        <TC />
      </DialogContent>
      <DialogActions>
        <Button onClick={accept}>Accept</Button>
        <Button onClick={reject}>Reject</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssertTerms;
