import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { AppBar, Button, Toolbar, Divider, Box } from "@material-ui/core";
import About from "./About";
import ModuleList from "./ModuleList";
import { StateWidget } from "./StateComponents";
import UserAvatar from "./NavBar/UserAvatar";
import Title from "./NavBar/Title";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  image: {
    display: "inline-block",
    width: "21%",
    float: "right",
  },
  imageText: {
    display: "inline-block",
    width: "70%",
    padding: 10,
    marginBottom: 15,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

const NavBar = () => {
  const classes = useStyles();

  const isAuthenticated = true;

  const clickLogout = () => {
    if (
      !window.confirm(
        "Logging out will take you back to ELAC.  Are you sure you want to do this?"
      )
    )
      return;
    fetch("/sso/logout", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => (window.location = res.redirect));
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Title />
        <Divider
          orientation="vertical"
          flexItem
          style={{ backgroundColor: "white" }}
        />
        <StateWidget />
        <Divider
          orientation="vertical"
          flexItem
          style={{ backgroundColor: "white" }}
        />
        <ModuleList style={{ paddingLeft: 25 }} />

        <Box flexGrow="1">
          <About classes={classes} />
        </Box>

        {isAuthenticated && (
          <Button color="inherit" onClick={clickLogout}>
            Logout
          </Button>
        )}
        <UserAvatar />
      </Toolbar>
    </AppBar>
  );
};

NavBar.propTypes = {
  title: PropTypes.string,
};

export default NavBar;
