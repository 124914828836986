import React, { useState } from "react";

import {
  Paper,
  Typography,
  Box,
  Link,
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Logo from "../images/logo.png";
import { DialogTC } from "./TermsAndConditions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyDialog = ({ open, title, onClose, children }) => {
  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

const DialogDis = ({ open, onClose }) => {
  return (
    <MyDialog open={open} onClose={onClose} title="Disclaimer">
      <Typography gutterBottom>
        This Course System and the services associated with the Course System
        accessible on, or distributed, copied from fxoptions.acifma.com (“Course
        System”) and the FX Options Course (“Course”) or any sections of the
        Course, including the correspondent Intellectual Property Rights, are
        owned and provided by ACI Financial Markets Association (“ACI FMA”),
        whose registered office is located at 8 Rue du Mail, 75002 Paris,
        France.
      </Typography>
      <Typography gutterBottom>
        The contents of the Course System, including the content of the Course
        (including updates, supplements and amendments) do not necessarily
        reflect the views of the entity, organisation or (working) group that
        any ACI FMA member or non-member works for, belongs to or is otherwise
        associated with ACI FMA.
      </Typography>
      <Typography gutterBottom>
        Nothing in the Course System or in the Course shall be construed as an
        assurance that any transaction could be entered into with the
        institution associated with the members of ACI FMA or any other party,
        individual members or non-members, even if the terms and conditions of
        such transaction are in understanding with the content of the Course.
      </Typography>
      <Typography gutterBottom>
        Any person using the Course System or the Course for any purpose should
        satisfy itself of its appropriateness for the intended use or purpose,
        and ensure that all applicable laws, regulations, codes, rules and other
        requirements (“Applicable Laws”), which generally have more detailed
        requirements, are complied with. The Course System and the Course are
        not intended to provide legal, regulatory, tax, accounting or other
        general or specific advice; therefore, in the event of conflict or
        inconsistency between such Applicable Laws and the Course, the
        Applicable Laws must be given priority.{" "}
      </Typography>
      <Typography gutterBottom>
        Any person referencing the Course must seek the advice of appropriate
        professionals, as he/she deems necessary. None of the members of ACI FMA
        or any associated organisation warrants, whether expressly or by
        implication, or shall be responsible for, the accuracy, completeness, or
        fitness or appropriateness for any particular purpose of the Course or
        any of the contents thereof. ACI FMA and its members expressly disclaim
        any liability as to the consequences, direct or indirect, of any action
        or inaction taken pursuant to use of the Course System or the Course.{" "}
      </Typography>
    </MyDialog>
  );
};

const Footer = (props) => {
  const [showTC, setShowTC] = useState(false);
  const [showDis, setShowDis] = useState(false);
  const year = new Date().getFullYear();

  const showTerms = () => {
    setShowTC(true);
  };

  const showDisclaimer = () => {
    setShowDis(true);
  };

  return (
    <Paper
      style={{
        background: "#F9F4EE",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        padding: 15,
        zIndex: 5,
      }}
      elevation={3}
    >
      <Box style={{ textAlign: "center" }}>
        <Box style={{ display: "inline-block" }}>
          <img
            src={Logo}
            alt="ACI FMA"
            style={{
              width: 120,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          />
        </Box>
        <Box style={{ display: "inline-block", verticalAlign: "top" }}>
          <Typography variant="body2" style={{ textAlign: "left" }}>
            © {year} ACI Financial Markets Association
          </Typography>
          <Link
            style={{ paddingRight: 10 }}
            component="button"
            onClick={showTerms}
          >
            Terms and Conditions
          </Link>
          |
          <Link
            style={{ paddingLeft: 10, paddingRight: 10 }}
            component="button"
            onClick={showDisclaimer}
          >
            Disclaimer
          </Link>
          |
          <Link href="mailto:fxoptions@acifma.com" style={{ paddingLeft: 10 }}>
            Support
          </Link>
        </Box>
      </Box>
      <DialogTC open={showTC} onClose={() => setShowTC(false)} />
      <DialogDis open={showDis} onClose={() => setShowDis(false)} />
    </Paper>
  );
};

export default Footer;
