import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import TargetIcon from "@material-ui/icons/TrackChanges";
import GearIcon from "@material-ui/icons/Settings";

const NextDialog = (props) => {
  const { open, course, onClose, nextClick, handleEnd } = props;

  const content = () => {
    if (course.is_final_step()) {
      return "You have now completed this course.";
    }
    if (course.is_last_step()) {
      return `You have completed the last activity in this module, press NEXT to start the next module.`;
    }
    return "You have completed this section.";
  };

  const handleNext = () => {
    onClose();
    nextClick();
  };

  const activity = course.currentstep();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{activity.title}</DialogTitle>
      <DialogContent dividers>
        <Typography align="center" variant="h6" gutterBottom>
          <DoneIcon style={{ color: "green" }} /> {content()}
        </Typography>
        {activity.knowledge && (
          <Box style={{ marginTop: 20, marginBottom: 20 }}>
            <Typography variant="h6">
              <TargetIcon style={{ color: "red", verticalAlign: "middle" }} />{" "}
              Learning objective:
            </Typography>
            <Typography gutterBottom>{activity.knowledge}</Typography>
          </Box>
        )}
        {activity.application && (
          <Box>
            <Typography variant="h6">
              <GearIcon style={{ color: "blue", verticalAlign: "middle" }} />{" "}
              Application:
            </Typography>
            <Typography gutterBottom>{activity.application}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!course.is_final_step() ? (
          <Button onClick={handleNext}>Next</Button>
        ) : (
          <Button
            onClick={() => {
              onClose();
              handleEnd();
            }}
          >
            Finish
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NextDialog;
