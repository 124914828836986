import { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
} from "@material-ui/core";
import Iframe from "react-iframe";
import PropTypes from "prop-types";
import VideoIcon from "@material-ui/icons/OndemandVideo";
import VideoActivity from "./VideoActivity";
import { CloseButton, TextButton } from "../ActionButtons";
import BriefingDialog from "../BriefingDialog";
import { StateContext } from "../../lib/StateContext";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "0 !important",
    top: "40px !important",
  },
  topbar: { padding: 10 },
  button: { marginLeft: 5, marginRight: 10 },
  videoicon: { marginRight: 5 },
}));

const VideoDialog = (props) => {
  const { title, video, open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        {title}
        <CloseButton onClick={onClose} />
      </DialogTitle>

      <DialogContent>
        <VideoActivity video={video} />
      </DialogContent>
    </Dialog>
  );
};

const Video = (props) => {
  const { title, video, open, onClose, onOpen } = props;
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.button}
        color="inherit"
        variant="outlined"
        disabled={!video}
        onClick={onOpen}
      >
        <VideoIcon className={classes.videoicon} /> View {title}
      </Button>
      <VideoDialog open={open} title={title} onClose={onClose} video={video} />
    </>
  );
};

const ExerciseActivity = (props) => {
  const { step, handleDone, handleStart } = props;
  const classes = useStyles();

  /*
    State: 1-5
    1: Briefing
    2: Intro Vid
    3: game
    4: outro vid
    5: final dialog
  */
  const _nextState = (s) => {
    if (!step) return 0;
    s++;
    switch (s) {
      case 1:
        if (step.briefing) return s;
        else return _nextState(s);
      case 2:
        if (step.introVideo) return s;
        else return _nextState(s);
      case 3:
        handleStart();
        return s;
      case 4:
        if (step.outroVideo) return s;
        else return _nextState(s);
      case 5:
        handleDone();
        return s;
      default:
        return 5;
    }
  };
  const next = () => {
    setState(_nextState(state));
  };
  const [state, setState] = useState(_nextState(0));

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.action === "next") {
        next();
      }
    };
    window.addEventListener("message", handleMessage);
  });

  const { setStarted } = useContext(StateContext);

  const prefix = process.env.REACT_APP_API_URL || "";
  return (
    <Dialog className={classes.root} open={true} fullWidth={true} maxWidth="lg">
      <BriefingDialog
        open={state === 1}
        step={step}
        nextClick={() => {
          next();
          setStarted(step.id);
        }}
      />
      <DialogTitle>
        {step.introVideo && (
          <Video
            onOpen={() => setState(2)}
            open={state === 2}
            onClose={next}
            title="Intro Video"
            video={step.introVideo}
          />
        )}
        {step.outroVideo && (
          <Video
            onOpen={() => setState(4)}
            open={state === 4}
            onClose={next}
            title="Debriefing Video"
            video={step.outroVideo}
          />
        )}
        {step.title}
        <TextButton onClick={next} text="Next" />
      </DialogTitle>
      <Grid
        style={{ width: "100%", display: "flex", justifyContent: "center " }}
      >
        <Iframe
          url={prefix + "/api/game/" + step.name + "/index.html"}
          width="1280px"
          height="840px"
        />
      </Grid>
    </Dialog>
  );
};

ExerciseActivity.propTypes = {
  excercise: PropTypes.string,
};

export default ExerciseActivity;
