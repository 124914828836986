import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import TargetIcon from "@material-ui/icons/TrackChanges";

const ObjectiveButton = (props) => {
  const { module, style } = props;
  const [open, setOpen] = useState(false);
  if (module.objective)
    return (
      <>
        <Button onClick={() => setOpen(!open)} style={style}>
          <TargetIcon style={{ color: "red", marginRight: 5 }} /> Module
          learning objective
        </Button>
        <ObjectiveDialog
          open={open}
          module={module}
          onClose={() => setOpen(false)}
        />
      </>
    );
  else return null;
};

const ObjectiveDialog = (props) => {
  const { module, open, onClose } = props;

  if (!module.objective) return null;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{module.title}</DialogTitle>
      <DialogContent>
        <TargetIcon
          style={{ color: "red", verticalAlign: "middle", marginRight: 5 }}
        />
        {module.objective}
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

export default ObjectiveButton;
