import React, { useState } from "react";
import { Dialog, DialogTitle, Grid, Tabs, Tab, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  index: {},
  tab: {
    lineHeight: 1.2,
  },
  box: {
    height: "80%",
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 10,
    marginRight: 10,
    overflow: "scroll",
  },
  paper: {
    margin: 5,
    padding: 10,
  },
}));

const Text = (
  <>
    <h4 id="guide-introduction">Introduction</h4>
    <p>
      Global wholesale financial markets are dynamic and fast changing, so it is
      natural to expect that these conditions demand highly qualified people
      with wide-ranging market skills and knowledge.
    </p>
    <p>
      With that purpose in mind, ACI Financial Markets Education (“ACI FMA”)
      presents its FX Options Course (“Course”), which we believe is an
      appropriate complement to the current educational offering from our
      Association.
    </p>

    <h4 id="guide-objectives">Course Objectives</h4>
    <p>
      The Course aims to enhance the knowledge and competence of all individual
      market participants to FX Options, a unique derivative product which is
      extremely relevant for the foreign exchange market and traded by a
      diversified number of institutions in several countries around the world.
    </p>
    <p>
      The ACI FMA FX Options Course further shows and emphasizes how important
      this product is, for both traders and hedgers with roles in foreign
      exchange, regardless of the direction and amplitude of the move in the
      underlying currency pair.
    </p>

    <h4 id="guide-concept"> Concept and Format</h4>
    <p>
      The ACI FMA FX Options Course has been developed by a renowned and very
      experienced professional in financial markets, with significant competence
      and expertise in trading and managing Options for major financial
      institutions. Additionally, the creator of the ACI FMA FX Options Course
      has designed and implemented own systems aimed at supporting the
      development of Options businesses, whilst also working with relevant
      stakeholders to implement specifications for Options that are now
      considered global standards.
    </p>
    <p>
      The format of the ACI FMA FX Options Course follows the concept and
      structure that were originally developed, which we believe is important to
      respect the full content designed and implemented at its creation. We have
      also introduced some functions aimed at supporting the acquisition of
      knowledge, such as learning objectives and guidance on how to apply that
      knowledge, amongst others.{" "}
    </p>
    <p>
      Aimed at delivering an attractive experience for the users, the Course is
      presented through several visual tools such as images, graphs, videos and
      animations, with the written text and spoken words complementing its basic
      framework.
    </p>

    <h4 id="guide-target">Target Group</h4>
    <p>
      The ACI FMA FX Options Course has been designed for individual market
      participants in the foreign exchange market, from beginner and
      intermediate levels of derivatives’ knowledge, fulfilling the following
      roles in financial institutions and corporations:
    </p>
    <ul>
      <li>Dealers</li>
      <li>Product Structurers</li>
      <li>Sales Persons</li>
      <li>Risk Managers</li>
      <li>Asset Managers</li>
      <li>Credit Risk Officers</li>
    </ul>

    <h4 id="guide-structure">Course Structure and Duration</h4>
    <p>
      Split into 16 different modules, this standard Course is a comprehensive
      and very practical guide to the world of vanilla Options, as it combines
      the basic theoretical concepts that forms the backbone of these products
      with several examples and exercises that demonstrate how Options can be
      transacted in “real life”. In particular, 12 of those modules contain
      examples, exercises and/or active studies aimed at supporting users
      wishing to trade these products.{" "}
    </p>
    <p>
      The estimated duration of this Course is just under 4 hours, with more
      than half of that time allocated to working and completing practical
      examples, exercises and/or active studies.
    </p>
    <p>
      The Course is not requested to be finalised in one attempt, as it has been
      designed to allow multiple accesses so that users can navigate through it,
      and complete it, according to their availability.
    </p>

    <h4 id="guide-navigation">Course Navigation</h4>
    <p>
      To achieve the expected outcomes from this Course, users are recommended
      to navigate through it following the sequential order of the Modules (from
      1 to 16) as well as the sequential order of the sections included in each
      of the Modules, covering both the theoretical and the practical content of
      the Course.
    </p>
    <p>
      Where applicable, users are also recommended to carefully study the
      instructions shown in practical sections of the Course (for example, the
      Introduction Videos and Debriefing Videos of some exercises).
    </p>
    <p>
      By following these recommendations, we believe that users will find that
      the balance between theory and practice is appropriate and that it will
      support their knowledge acquisition and competence development.
    </p>

    <h4 id="guide-howto">How To Complete The Course</h4>
    <p>Here are some tips which could be useful for completing the Course:</p>
    <ul>
      <li>Manage your study time with efficiency</li>
      <li>
        Include the Course in your daily priority list (create a habit, even if
        just for a few minutes)
      </li>
      <li>Read the learning objectives and applications</li>
      <li>Build a study plan (with a calendar) and follow your progress</li>
      <li>
        Set goals and incentives related to the Course content (keep yourself
        accountable)
      </li>
      <li>Be wary of distractions that could damage your study</li>
      <li>Take notes and comments that can support your study</li>
      <li>Follow the recommended Course Navigation sequence</li>
      <li>If you have peers doing the same Course, share feedback with them</li>
    </ul>
    <p>
      Please appreciate the full content of this FX Options Course as we
      sincerely believe it will help you to acquire a relevant set of necessary
      skills for executing transactions in foreign exchange options.
    </p>

    <h4 id="guide-compatibility">Screen size and Browser compatibility</h4>
    <p>This course is designed to work on all modern browsers.</p>
    <p>
      Some smaller screen resolutions and geometries may cause you not to see
      all content properly. If you find that you have to scroll to see content,
      the most convenient solution is to reduce your browser zoom a bit. This
      can be achieved on most browsers by pressing “CTRL” or “CMD” and “-”
      (minus).
    </p>
  </>
);

const CourseIntro = (props) => {
  const { open, onClose } = props;
  const [value, setValue] = useState("introduction");
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    document.getElementById(`guide-${newValue}`)?.scrollIntoView();
  };

  return (
    <Dialog
      PaperProps={{ style: { height: "100%" } }}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      style={{ height: "100%" }}
    >
      <DialogTitle>FX Options Course - Course Information</DialogTitle>
      <Grid container style={{ height: "calc(100% - 64px)" }}>
        <Grid item xs={4}>
          <Tabs
            value={value}
            onChange={handleChange}
            className={classes.index}
            orientation="vertical"
          >
            <Tab
              className={classes.tab}
              label="Introduction"
              value="introduction"
            />
            <Tab
              className={classes.tab}
              label="Course Objectives"
              value="objectives"
            />
            <Tab
              className={classes.tab}
              label="Concept and Format"
              value="concept"
            />
            <Tab className={classes.tab} label="Target Group" value="target" />
            <Tab
              className={classes.tab}
              label="Course Structure and Duration"
              value="structure"
            />
            <Tab
              className={classes.tab}
              label="Course Navigation"
              value="navigation"
            />
            <Tab
              className={classes.tab}
              label="How To Complete The Course"
              value="howto"
            />
            <Tab
              className={classes.tab}
              label="Screen size and Browser compatibility"
              value="compatibility"
            />
          </Tabs>
        </Grid>
        <Grid item xs={8} style={{ height: "100%" }}>
          <Paper className={classes.box}>{Text}</Paper>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CourseIntro;
