import * as React from "react";
const SvgGraduationCapRegular = (props) => (
  <svg width={26} height={18} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M25.035 4.215 13.677.149a2.21 2.21 0 0 0-.82-.149c-.29 0-.574.05-.852.148L.645 4.215A.985.985 0 0 0 0 5.143c0 .418.259.79.645.928l2.411.864a5.555 5.555 0 0 0-.986 2.167c-.46.195-.784.653-.784 1.184 0 .4.193.743.48.978l-.998 5.987a.643.643 0 0 0 .634.749H3.74c.397 0 .7-.357.634-.749l-.998-5.986a1.32 1.32 0 0 0 .48-.98c0-.413-.208-.764-.51-1a4.271 4.271 0 0 1 1.001-1.889l7.658 2.741a2.524 2.524 0 0 0 1.703 0l11.359-4.065a.983.983 0 0 0-.033-1.857ZM13.062 8.32a.6.6 0 0 1-.405 0L7.05 6.316l5.995-1.845a.643.643 0 1 0-.38-1.228L5.03 5.593l-1.255-.45 8.843-3.18a.6.6 0 0 1 .406 0l8.914 3.18-8.876 3.178Zm5.11 1.583.47 4.508c0 .426-1.988 1.66-5.785 1.66s-5.786-1.235-5.786-1.662l.471-4.504-1.851-.663-.543 5.067C5.143 16.767 9.024 18 12.858 18c3.832 0 7.713-1.233 7.71-3.689l-.543-5.066-1.852.659Z"
      fill="#B8AE98"
      fillRule="nonzero"
    />
  </svg>
);
export default SvgGraduationCapRegular;
