const logActivity = (activity_id: Number, activity: String) => {
  const prefix = process.env.REACT_APP_API_URL || "";

  return fetch(prefix + `/api/activity/${activity_id}/${activity}`, {
    method: "PUT",
  });
};

export const logStartActivity = async (activity_id: Number) => {
  return logActivity(activity_id, "start");
};

export const logCompleteActivity = async (activity_id: Number) => {
  return logActivity(activity_id, "complete");
};

export const logAcceptActivity = async (activity_id: Number) => {
  return logActivity(activity_id, "accept");
};
