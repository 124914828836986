import { Course, Module, CourseActivity } from "./course";

interface State {
  module_id: number;
  step_id: number;
}

interface CourseState {
  moduleno: number;
  stepno: number;
  course: Course;
  finished: boolean;
  state: State;
  setState: Function;
}

class CourseState implements CourseState {
  constructor(course: Course, state: State, setter: Function) {
    this.course = course;
    this.moduleno = 1;
    this.stepno = 1;
    this.finished = false;
    this.state = state;
    this.setState = setter;
  }

  updateState(): any {
    if (
      this.state &&
      (this.state.module_id !== this.moduleno ||
        this.state.step_id !== this.stepno)
    ) {
      this.setState &&
        this.setState({ module_id: this.moduleno, step_id: this.stepno });
    }
  }

  moduleidx(): number {
    return this.moduleno - 1;
  }

  stepidx(): number {
    return this.stepno - 1;
  }

  goto(module: number, step: number): CourseState {
    if (
      module > 0 &&
      module <= this.course.modules.length &&
      step > 0 &&
      step <= this.course.modules[module - 1].CourseActivities.length
    ) {
      // Because WTF Javascript
      this.stepno = Number(step);
      this.moduleno = Number(module);
    } else {
      this.stepno = 1;
      this.moduleno = 1;
    }
    this.updateState();
    return this;
  }

  gotostep(step: number): CourseState {
    if (
      step > 0 &&
      step <= this.course.modules[this.moduleidx()].CourseActivities.length
    ) {
      this.stepno = Number(step);
    }
    this.updateState();
    return this;
  }

  next(): CourseState {
    if (
      this.course.modules[this.moduleidx()].CourseActivities.length <=
      this.stepno
    ) {
      if (this.course.modules.length === this.moduleno) {
        this.finished = true;
      } else {
        this.stepno = 1;
        this.moduleno++;
      }
    } else {
      this.stepno++;
    }
    return this;
  }

  prev(): CourseState {
    if (this.stepno === 1) {
      if (this.moduleno > 1) {
        this.moduleno--;
        this.stepno = this.course.modules[
          this.moduleidx()
        ].CourseActivities.length;
      }
    } else {
      this.stepno--;
    }
    return this;
  }

  currentmodule(): Module {
    return this.course.modules[this.moduleidx()];
  }

  module(modno: number): Module {
    return this.course.modules[modno - 1];
  }

  previousmodule(): Module | undefined {
    return this.course.modules[this.moduleidx() - 1];
  }

  nextmodule(): Module | undefined {
    return this.course.modules[this.moduleidx() + 1];
  }

  currentstep(): CourseActivity {
    return this.currentmodule().CourseActivities[this.stepidx()];
  }

  previousstep(): CourseActivity | undefined {
    return this.currentmodule().CourseActivities[this.stepidx() - 1];
  }

  nextstep(): CourseActivity | undefined {
    return this.currentmodule().CourseActivities[this.stepidx() + 1];
  }

  is_last_step(): Boolean {
    return this.currentmodule().CourseActivities.length === this.stepno;
  }

  is_last_module(): Boolean {
    return this.course.modules.length === this.moduleno;
  }

  is_final_step(): Boolean {
    return this.is_last_module() && this.is_last_step();
  }
}

export { CourseState };
