import React, { useEffect, useState, useContext } from "react";
import Player from "react-player/lazy";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, LinearProgress, Typography, Box } from "@material-ui/core";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import PauseIcon from "@material-ui/icons/PauseCircleFilled";
import Forward10Icon from "@material-ui/icons/Forward10";
import Replay10Icon from "@material-ui/icons/Replay10";
import toTimeString from "../../lib/toTimeString";
import { UserContext } from "../../lib/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    transition: "opacity 0.5s linear",
    zIndex: 3, // Required to make buttons clickable
  },
  buttonBox: {
    display: "flex",
    paddingTop: 130,
    paddingLeft: 100,
    paddingRight: 100,
  },
  buttons: {
    display: "inline-block",
    margin: "auto",
    fontSize: 120,
  },
  progressBar: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: "auto",
    marginBottom: 10,
    height: 20,
    cursor: "pointer",
  },
  progressTime: {
    marginRight: 10,
    textAlign: "right",
  },
}));

const ProgressBar = (props) => {
  const { progress, seekTo } = props;
  const classes = useStyles();

  const handleBarClick = (event) => {
    const clickLocation = event.nativeEvent.pageX;
    const rect = event.currentTarget.getBoundingClientRect();
    seekTo((clickLocation - rect.x) / rect.width);
  };

  return (
    <LinearProgress
      onMouseDown={handleBarClick}
      className={classes.progressBar}
      variant="buffer"
      value={progress.played * 100}
      valueBuffer={progress.loaded * 100}
    />
  );
};

const ControlsOverlay = (props) => {
  const {
    children,
    player,
    playing,
    setPlaying,
    progress,
    duration,
    visible,
    setVisible,
    allowSeek,
  } = props;
  const classes = useStyles();

  var timer;
  const setMoving = () => {
    setVisible(true);
    clearTimeout(timer);
    timer = setTimeout(() => setVisible(false), 5000);
  };

  const back = (sec) => {
    const t = player.current.getCurrentTime();
    player.current.seekTo(t - sec, "seconds");
  };

  const forward = (sec) => {
    const t = player.current.getCurrentTime();
    const maxt = player.current.getDuration();
    if (maxt > t + sec) player.current.seekTo(t + sec, "seconds");
  };

  return (
    <div
      className={classes.root}
      onMouseLeave={() => setVisible(false)}
      onMouseMove={() => setMoving()}
    >
      <div className={classes.overlay} style={{ opacity: visible ? 1 : 0 }}>
        <Box className={classes.buttonBox}>
          {allowSeek && (
            <IconButton className={classes.buttons} onClick={() => back(10)}>
              <Replay10Icon fontSize="inherit" />
            </IconButton>
          )}
          {playing ? (
            <IconButton
              className={classes.buttons}
              onClick={() => setPlaying(false)}
            >
              <PauseIcon fontSize="inherit" />
            </IconButton>
          ) : (
            <IconButton
              className={classes.buttons}
              onClick={() => setPlaying(true)}
            >
              <PlayIcon fontSize="inherit" />
            </IconButton>
          )}
          {allowSeek && (
            <IconButton className={classes.buttons} onClick={() => forward(10)}>
              <Forward10Icon fontSize="inherit" />
            </IconButton>
          )}
        </Box>
        <ProgressBar
          progress={progress}
          seekTo={(frac) =>
            allowSeek && player.current.seekTo(frac, "fraction")
          }
        />

        <Typography className={classes.progressTime}>
          {toTimeString(progress.played * duration)} / {toTimeString(duration)}
        </Typography>
      </div>
      {children}
    </div>
  );
};

const VideoPlayer = (props) => {
  const { uri, handleStart, handleNext, allowSeek } = props;
  const ref = React.createRef();
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState({ played: 0, loaded: 0 });
  const [duration, setDuration] = useState(null);

  const [visible, setVisible] = useState(true);

  const onProgress = (p) => {
    setProgress(p);
  };
  const onDuration = (p) => {
    setDuration(p);
  };

  const onEnded = () => {
    setPlaying(false);
    handleNext && handleNext();
  };

  return (
    <ControlsOverlay
      player={ref}
      playing={playing}
      setPlaying={setPlaying}
      progress={progress}
      duration={duration}
      visible={visible}
      setVisible={setVisible}
      allowSeek={allowSeek}
    >
      <Player
        width={854}
        height={480}
        url={uri}
        ref={ref}
        playing={playing}
        controls={false}
        onStart={handleStart}
        onReady={() => setVisible(true)}
        onEnded={onEnded}
        onProgress={onProgress}
        onDuration={onDuration}
        progressInterval={500}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
              preload: "auto",
            },
          },
        }}
      />
    </ControlsOverlay>
  );
};

const VideoActivity = (props) => {
  const { video, handleDone, handleStart, allowSeek } = props;
  const prefix = process.env.REACT_APP_API_URL || "";

  const [uri, setUri] = useState();
  const { fetch } = useContext(UserContext);
  useEffect(() => {
    fetch(prefix + "/api/video/" + video)
      .then((res) => res.json())
      .then((data) => setUri(data.url));
  }, [prefix, video]);

  if (!uri) return null;

  return (
    <VideoPlayer
      uri={uri}
      handleNext={handleDone}
      handleStart={handleStart}
      allowSeek={allowSeek}
    />
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.object.isRequired,
  seekTo: PropTypes.func.isRequired,
};

VideoActivity.propTypes = {
  video: PropTypes.string.isRequired,
  handleDone: PropTypes.func,
  handleStart: PropTypes.func,
  allowSeek: PropTypes.bool,
};

export default VideoActivity;
