import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PieChart } from "react-minimal-pie-chart";
import { Box, Divider } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { CourseContext } from "../lib/CourseContext";
import { StateContext } from "../lib/StateContext";

const InProgressIcon = (props) => {
  const { complete, started, total } = props;
  const data = [
    { value: complete, color: "green" },
    { value: started, color: "yellow" },
    { value: total - complete - started, color: "#d0d0d0" },
  ];

  return (
    <PieChart data={data} style={{ height: 25, width: 25, paddingLeft: 15 }} />
  );
};

const ModuleStateIcon = (props) => {
  const { module, completed, classes } = props;
  const activitycount = module.CourseActivities.length;
  const completecount = module.CourseActivities.filter(
    (act) => completed[act.id]
  ).length;
  if (activitycount > 0 && completecount > 0) {
    if (completecount === activitycount) {
      return <DoneIcon className={classes.icon} />;
    } else {
      return (
        <InProgressIcon
          complete={completecount}
          started={0}
          total={activitycount}
          className={classes.icon}
        />
      );
    }
  }
  return <div className={classes.icon} style={{ width: 30 }} />;
};

const OverAllStateIcon = (props) => {
  const { classes } = props;

  const { course } = useContext(CourseContext);
  const { getStarted, getCompleted } = useContext(StateContext);

  const total = course.course.modules.reduce(
    (acc, curr) => acc + curr.CourseActivities.length,
    0
  );
  const completed = Object.keys(getCompleted());
  const started = Object.keys(getStarted()).filter((id) => !getCompleted()[id]);

  return (
    <InProgressIcon
      complete={completed.length}
      started={started.length}
      total={total}
      className={classes.icon}
    />
  );
};

const StateWidget = () => {
  const { course } = useContext(CourseContext);
  const { getCompleted } = useContext(StateContext);

  if (!course) return null;

  // Flatten activities
  const activities = course.course.modules.reduce(
    (acc, curr) => [...acc, ...curr.CourseActivities],
    []
  );

  const total = activities.length;
  const completed = Object.keys(getCompleted()).map((s) => parseInt(s));
  const percent = Math.round((100 * completed.length) / total);

  const cpd = Math.round(
    activities
      .filter((a) => completed.includes(a.id))
      .reduce((acc, curr) => (acc += parseFloat(curr.length)), 0) / 60
  );

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: 140,
        fontSize: 14,
        paddingRight: 10,
        paddingLeft: 10,
      }}
    >
      <Box style={{ paddingBottom: 3 }}>
        <Box style={{ display: "inline" }}>Progress</Box>
        <Box style={{ display: "inline", float: "right" }}>{percent}%</Box>
      </Box>
      <Divider style={{ backgroundColor: "white" }} />
      <Box style={{ paddingTop: 3 }}>
        <Box style={{ display: "inline" }}>CPD minutes</Box>
        <Box style={{ display: "inline", float: "right" }}>{cpd}</Box>
      </Box>
    </Box>
  );
};

InProgressIcon.propTypes = {
  complete: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

ModuleStateIcon.propTypes = {
  module: PropTypes.object.isRequired,
  completed: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

StateWidget.propTypes = {};

export { ModuleStateIcon, OverAllStateIcon, StateWidget };
