import React, { useState, useContext } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import AccountIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import { UserContext } from "../../lib/UserContext";

const UserAvatar = (props) => {
  const { state } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton color="inherit" onClick={() => handleClick()}>
        <AccountIcon />
      </IconButton>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={open}
        onClose={() => handleClose()}
      >
        <DialogTitle id="simple-dialog-title">User Details</DialogTitle>
        <List>
          <ListItem>
            <EmailIcon style={{ paddingRight: 10 }} />
            <ListItemText primary={state.user?.email} />
          </ListItem>
        </List>
      </Dialog>
    </React.Fragment>
  );
};

export default UserAvatar;
